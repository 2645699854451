main {
  padding: 20px;
}

h1 {
  font-size: 32px;
  color: #4a90e2;
  text-align: center;
  margin-bottom: 20px;
}

.content {
  max-width: 800px;
  margin: 0 auto;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
}

.content p {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
}

@media (max-width: 768px) {
  h1 {
    font-size: 24px;
  }

  .content p {
    font-size: 16px;
  }
}
