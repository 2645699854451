main {
  padding: 20px;
}

h1 {
  font-size: 32px;
  color: #4a90e2;
  text-align: center;
  margin-bottom: 20px;
}

form {
  max-width: 600px;
  margin: 0 auto;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
}

label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

input, textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

button:hover {
  background-color: #357abd;
}

@media (max-width: 768px) {
  h1 {
    font-size: 24px;
  }

  form {
    padding: 15px;
  }

  button {
    font-size: 16px;
  }
}
