main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 36px;
  color: #4a90e2;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
  color: #666;
  max-width: 600px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  h1 {
    font-size: 28px;
  }

  p {
    font-size: 16px;
  }
}
