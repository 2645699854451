body {
    font-family: 'Arial', sans-serif;
    background-color: #f7f7f7;
    color: #2c3e50;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container {
    max-width: 600px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

a {
    color: #3498db;
    text-decoration: none;
    padding: 8px 16px;
    border: 2px solid #3498db;
    border-radius: 4px;
    transition: all 0.3s ease;
}

a:hover {
    background-color: #3498db;
    color: white;
}

button {
    background-color: #2ecc71;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #27ae60;
}
