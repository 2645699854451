main {
  padding: 20px;
}

h1 {
  font-size: 32px;
  color: #4a90e2;
  text-align: center;
  margin-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  background-color: #f4f4f4;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  font-size: 18px;
}

li:hover {
  background-color: #e0e0e0;
  cursor: pointer;
}

@media (max-width: 768px) {
  h1 {
    font-size: 24px;
  }

  li {
    font-size: 16px;
  }
}
