main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

h1 {
  font-size: 32px;
  color: #4a90e2;
  margin-bottom: 20px;
}

.profile-info {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.profile-info p {
  font-size: 18px;
  color: #666;
  margin: 10px 0;
}

@media (max-width: 768px) {
  h1 {
    font-size: 24px;
  }

  .profile-info {
    padding: 15px;
  }

  .profile-info p {
    font-size: 16px;
  }
}
